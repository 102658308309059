<template>
    <div>
        <div>
            <p id="business_name">{{business_name}}</p>
            <div @click="scan()" style="margin: 5vw auto">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/checkh5-扫码.png" style="width: 40vw;margin: 2vw 30vw">
                <p style="color:#666;text-align: center;width: 100%">扫码核销</p>
            </div>
            <div class="divCode">
                <p>核销码：</p>
                <input v-model="code" class="inputCode">
            </div>
            <div class="checkCode" @click="check()">确认核销</div>
        </div>
        <check-float-nav></check-float-nav>
    </div>
</template>

<script>
    import CheckFloatNav from '../../components/check/CheckFloatNav'
    import { globalConfig } from '../../utils/mixin'
    import wx from 'weixin-js-sdk'
    export default {
        name: 'Scan',
        mixins: [globalConfig],
        components: { CheckFloatNav },
        data () {
            return {
                business_name: '',
                code: ''
            }
        },
        methods: {
            check: function () {
                this.axios.post(this.apiUrl + 'checkh5/order/isCodeInfo', {
                    code: this.code
                }).then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 1) {
                        this.$router.push('/check/checkList/qr/' + data.id + '/' + data.sign)
                    } else {
                        this.$router.push('/check/warning/' + data.msg)
                    }
                })
            },
            scan: function () {
                console.log(11)
                const that = this
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        console.log(res)
                        const result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                        that.code = result
                    }
                })
            },
            init: function () {
                this.axios.get(this.apiUrl + 'checkh5/api/init').then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 0) {
                        this.$router.replace('/check/login')
                    } else {
                        this.business_name = data.business_name
                        console.log('初始化成功')
                    }
                })
            }
        },
        created () {
            this.init()
        },
        mounted () {
        },
        computed: {
        }
    }
</script>

<style scoped>
    body{
        background-color: #ffffff;
        margin: 0;
        padding: 0;
    }
    #business_name{
        width: 100vw;
        padding: 2vw 0;
        border:1px solid #eee;
        text-align: center;
        font-size: 3.6vw;
        font-weight: 600;
    }
    .divCode{
        width: 100vw;
        display: flex;
        margin: 10vw 0  2vw 0;
        justify-content: center;
        align-items: center;
    }
    .inputCode{
        width: 60vw;
        height: 10vw;
        border: 1px solid #999;
        border-radius: 1vw;
        text-indent: 2vw;
        color: #666;
        font-weight: 600;
        font-size: 4.2vw;
    }
    .checkCode{
        width: 80vw;
        height: 10vw;
        margin: 5vw 10vw;
        border-radius: 1vw;
        color: #fff;
        font-weight: 600;
        font-size: 4.2vw;
        background-color: #C20B09;
        line-height: 10vw;
        text-align: center;
    }
</style>
